import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "gatsby";

function PaginationArrow({ page, isLeft, basePath }) {
  const redirectLink = basePath + (page > 1 ? `/page/${page}` : "/");

  const arrowClassname = classnames("arrow", "pagination__arrow", {
    "arrow--left": isLeft,
  });

  return (
    <li className="pagination__item">
      <Link to={redirectLink} className="pagination__link">
        <div className={arrowClassname}></div>
      </Link>
    </li>
  );
}

PaginationArrow.propTypes = {
  page: PropTypes.number.isRequired,
  isLeft: PropTypes.bool.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default PaginationArrow;
