import React, { useMemo } from "react";
import PropTypes from "prop-types";

import PaginationItem from "./PaginationItem";
import PaginationArrow from "./PaginationArrow";
import PaginationDots from "./PaginationDots";

import { range } from "../../utils/range";
import {
  PAGINATION_ADDITIONAL_PAGE_NUMBER,
  PAGINATION_DEFAULT_PAGE_NUMBERS_COUNT_WITH_ONE_DOTS,
  PAGINATION_DOTS_VALUE,
  PAGINATION_FIRST_PAGE_NUMBER,
  PAGINATION_MAIN_PAGE_NUMBERS_COUNT,
  PAGINATION_SIBLING_INDEX_BOUND,
  PAGINATION_SIBLING_PAGE_NUMBERS_COUNT,
  PAGINATION_SIBLING_PAGE_NUMBERS_COUNT_MULTIPLIER,
} from "../../constants/pagination";

function Pagination({ pageCount: lastPageNumber, currentPage, basePath }) {
  const paginationNumberRange = useMemo(() => {
    const totalPageNumbersCount =
      PAGINATION_SIBLING_PAGE_NUMBERS_COUNT +
      PAGINATION_MAIN_PAGE_NUMBERS_COUNT;

    if (totalPageNumbersCount >= lastPageNumber) {
      return range(PAGINATION_FIRST_PAGE_NUMBER, lastPageNumber);
    }

    const leftSiblingIndex = Math.max(
      currentPage - PAGINATION_SIBLING_PAGE_NUMBERS_COUNT,
      PAGINATION_FIRST_PAGE_NUMBER
    );
    const rightSiblingIndex = Math.min(
      currentPage + PAGINATION_SIBLING_PAGE_NUMBERS_COUNT,
      lastPageNumber
    );

    const isVisibleLeftDots = leftSiblingIndex > PAGINATION_SIBLING_INDEX_BOUND;
    const isVisibleRightDots =
      rightSiblingIndex < lastPageNumber - PAGINATION_SIBLING_INDEX_BOUND;

    if (!isVisibleLeftDots && isVisibleRightDots) {
      const leftItemCount =
        PAGINATION_DEFAULT_PAGE_NUMBERS_COUNT_WITH_ONE_DOTS +
        PAGINATION_SIBLING_PAGE_NUMBERS_COUNT_MULTIPLIER *
          PAGINATION_SIBLING_PAGE_NUMBERS_COUNT;
      const leftRange = range(PAGINATION_FIRST_PAGE_NUMBER, leftItemCount);
      return [...leftRange, PAGINATION_DOTS_VALUE, lastPageNumber];
    }

    if (isVisibleLeftDots && !isVisibleRightDots) {
      const rightItemCount =
        PAGINATION_DEFAULT_PAGE_NUMBERS_COUNT_WITH_ONE_DOTS +
        PAGINATION_SIBLING_PAGE_NUMBERS_COUNT_MULTIPLIER *
          PAGINATION_SIBLING_PAGE_NUMBERS_COUNT;
      const rightRange = range(
        lastPageNumber - rightItemCount + PAGINATION_ADDITIONAL_PAGE_NUMBER,
        lastPageNumber
      );
      return [
        PAGINATION_FIRST_PAGE_NUMBER,
        PAGINATION_DOTS_VALUE,
        ...rightRange,
      ];
    }

    if (isVisibleLeftDots && isVisibleRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [
        PAGINATION_FIRST_PAGE_NUMBER,
        PAGINATION_DOTS_VALUE,
        ...middleRange,
        PAGINATION_DOTS_VALUE,
        lastPageNumber,
      ];
    }
  }, [lastPageNumber, currentPage]);

  return (
    <ul className="pagination">
      {currentPage > PAGINATION_FIRST_PAGE_NUMBER && (
        <PaginationArrow
          isLeft={true}
          page={currentPage - 1}
          basePath={basePath}
        />
      )}

      {paginationNumberRange.map((pageNumber, index) => {
        if (pageNumber === PAGINATION_DOTS_VALUE) {
          return <PaginationDots key={`${pageNumber}_${index}`} />;
        }
        return (
          <PaginationItem
            key={pageNumber}
            isActive={pageNumber === currentPage}
            page={pageNumber}
            basePath={basePath}
          />
        );
      })}

      {currentPage < lastPageNumber && (
        <PaginationArrow
          isLeft={false}
          page={currentPage + 1}
          basePath={basePath}
        />
      )}
    </ul>
  );
}

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  basePath: PropTypes.string.isRequired,
};

export default Pagination;
