import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function HomeBlogNavListItem({ to, children }) {
  return (
    <li className="blogNav__list__item">
      <Link to={to} className="blogNav__link">
        {children}
      </Link>
    </li>
  );
}

HomeBlogNavListItem.propTypes = {
  to: PropTypes.string.isRequired,
};

export default HomeBlogNavListItem;
