import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/helmet/SEO";
import Layout from "../components/layout/Layout";
import HomeBlogNav from "../components/layout/blog-nav/home/BlogNav";
import ArticleList from "../components/article-list/ArticleList";
import Pagination from "../components/pagination/Pagination";
import useSiteMetaData from "../hooks/useSiteMetadata";
import { getArticleArrayFromQuery } from "../utils/blog";
import {getUrl} from '../utils/url';

function ListTemplate({ pageContext, data, location }) {
 const { siteUrl } = useSiteMetaData();
 const { numPages, currentPage } = pageContext;
 const articles = getArticleArrayFromQuery(data.allMarkdownRemark);

 const ogImage = getUrl(siteUrl, articles[0].imagePublicURL);

 return (
  <Layout footerMoved>
   <SEO
    showTwitterTitle
    title="Great Software Development Explained"
    pathname={location.pathname}
    canonicalHref={getUrl(siteUrl, location.pathname)}
    ogImage={ogImage}
   />
   <HomeBlogNav />
   <main className="main">
    <section className="articles background">
     <ArticleList className="container" articles={articles} />
     <Pagination basePath="" currentPage={currentPage} pageCount={numPages} />
    </section>
   </main>
  </Layout>
 );
}

export const query = graphql`
 query ($limit: Int!, $skip: Int!) {
  allMarkdownRemark(
   sort: { fields: [fields___date], order: DESC }
   limit: $limit
   skip: $skip
  ) {
   ...ArticleListData
  }
 }
`;

export default ListTemplate;
