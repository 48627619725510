import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import HomeBlogNavListItem from "./BlogNavListItem";
import { formatCategory } from "../../../../utils/string";

function HomeBlogNavList() {
 const { allMarkdownRemark } = useStaticQuery(graphql`
  query {
   allMarkdownRemark {
    group(field: frontmatter___categories) {
     category: fieldValue
    }
   }
  }
 `);

 return (  
  <ul className="blogNav__list">
   {allMarkdownRemark.group.map(({ category }) => (
    <HomeBlogNavListItem to={`/categories/${category}`} key={category}>
     {formatCategory(category)}
    </HomeBlogNavListItem>
   ))}
  </ul>
 );
}

export default HomeBlogNavList;
