import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "gatsby";

function PaginationItem({ page, isActive, basePath }) {
  const redirectLink = basePath + (page > 1 ? `/page/${page}` : "/");

  const linkClassname = classnames("pagination__link", {
    "pagination__link--active": isActive,
  });

  const textClassname = classnames("pagination__text", {
    "pagination__text--active": isActive,
  });

  return (
    <li className="pagination__item">
      <Link to={redirectLink} className={linkClassname}>
        <span className={textClassname}>{page}</span>
      </Link>
    </li>
  );
}

PaginationItem.propTypes = {
  page: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  basePath: PropTypes.string.isRequired,
};

export default PaginationItem;
