import React from "react";

import BlogNav from "../BlogNav";
import HomeBlogNavList from "./BlogNavList";
import HomeBlogNavSearch from "../search/Search";

function HomeBlogNav() {

 return (
  <BlogNav>
   <div className="blogNav__startFiller"></div>
   <h1 className="blogNav__slogan">Great software development explained</h1>
   <HomeBlogNavList />
   <HomeBlogNavSearch />
  </BlogNav>
 );
}

export default HomeBlogNav;
